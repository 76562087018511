import React from "react";
import cx from "classnames";

import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import Cover from "../components/cover/cover";
import Seo from "../components/seo/seo";
import Excerpt from "../components/excerpt/excerpt";
import { URLS } from "../config";

import Share from "../components/share/share";
import ScrollSection from "../components/scroll-section/scroll-section";
import { DocumentToReactComponents } from "../components/blog-elements/blog-elements";
import TransitionLink from "../components/transition-link/transition-link";
import Image from "../components/image/image";

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulPost");
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const postTitle = post.title;
    const relatedPosts = get(this.props, "data.allContentfulPost");
    const postSlug = this.props.pageContext.slug;
    const continentName = post.country.continent.title;
    const continentURL = `${URLS.Archive}${post.country.continent.slug}/`;
    const countryName = post.country.title;
    const countryURL = `${continentURL}${post.country.slug}/`;
    const pageURL = `${countryURL}${postSlug}`;

    // const postCategories = post.category;
    const categories = post.categories;
    // https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/
    // https://github.com/contentful/rich-text/blob/master/packages/rich-text-types/src/blocks.ts

    return (
      <Layout
        continents={continents}
        classNames={{
          container: "bg-white",
          navigation: "blog-post",
        }}
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo type="blog" content={post} slug={pageURL} />
        <ScrollSection>
          <article>
            <div id="hero" className="mb-20">
              <Cover type="hero" post={post} />
              <Excerpt
                excerpt={post.excerpt}
                country={post.country}
                share={{
                  title: postTitle,
                  text: postTitle,
                  slug: pageURL,
                }}
              />
            </div>
            <div className={cx("post-wrapper bg-white")}>
              <div className={cx("")}>
                {post.content &&
                  DocumentToReactComponents(post.content, {
                    country: {
                      name: countryName,
                      url: countryURL,
                    },
                    related: {
                      current: postSlug,
                      posts: relatedPosts,
                    },
                  })}
                {categories ? (
                  <aside className="post-component leading-none text-sm my-16 max-w-4xl lg:ml-[25%] px-5 lg:pr-10">
                    <nav>
                      <ul className="flex flex-wrap items-center">
                        <li className="basis-full md:basis-0 font-bold uppercase text-xs mb-2 mr-5 text-black">
                          Tags{" "}
                        </li>
                        <li className="mb-2 mr-2">
                          <TransitionLink
                            to={`${URLS.Archive}${post.country.continent.slug}/`}
                            className="transition duration-300 bg-primary-blue hover:bg-black text-white rounded-xl flex items-center p-2 px-3 tag"
                          >
                            {post.country.continent.title}
                          </TransitionLink>
                        </li>
                        <li className="mb-2 mr-2">
                          <TransitionLink
                            to={`${URLS.Archive}${post.country.continent.slug}/${post.country.slug}/`}
                            className="transition duration-300 bg-primary-blue hover:bg-black text-white rounded-xl flex items-center p-2 px-3 tag"
                          >
                            {post.country.title}
                          </TransitionLink>
                        </li>
                        {categories.map((category) => (
                          <li className="mb-2 mr-2" key={category.slug}>
                            <TransitionLink
                              to={`${URLS.Tagged}${category.slug}/`}
                              className="transition duration-300 bg-primary-blue hover:bg-black text-white rounded-xl flex items-center p-2 px-3 tag"
                            >
                              {category.title}
                            </TransitionLink>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </aside>
                ) : (
                  ""
                )}
                <div className="post-component border-t border-gray-300 pt-7 md:pt-14 my-16 max-w-4xl lg:ml-[25%] px-5 lg:pr-10">
                  <div className="md:flex items-center">
                    <div className="mb-5 md:mb-0">
                      <TransitionLink
                        to={`${URLS.Contributor}${post.author.slug}/`}
                      >
                        <Image
                          className="h-28 w-28 objectfit-cover rounded-full transition-transform duration-300 hover:-translate-y-4"
                          alt={post.author.firstname}
                          gatsbyImageData={post.author.picture}
                        />
                      </TransitionLink>
                    </div>
                    <div className="w-full md:ml-5">
                      <p className="post-text--md text-black mb-2">
                        {post.author.shortBio}
                      </p>
                      <p className="post-text--sm text-black">
                        {post.author.location ? (
                          <>
                            <strong>Currently in</strong>:{" "}
                            {post.author.location} &mdash; &nbsp;
                          </>
                        ) : (
                          ""
                        )}
                        <a
                          href={`https://www.twitter.com/${post.author.twitter}`}
                          target="_blank"
                          rel="noreferrer"
                          className={cx(
                            "font-semibold transition duration-300 p-0.5 has-underline text-primary-red hover:bg-red-lightest hover:text-red-accessible"
                          )}
                        >
                          @{post.author.twitter}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="my-16 max-w-4xl lg:ml-[25%] px-5 lg:pr-10 text-black">
                  <Share title={postTitle} text={postTitle} slug={pageURL} />
                </div>
              </div>
            </div>
            <Breadcrumb
              className="my-16"
              items={[
                {
                  key: "id_1",
                  link: URLS.Archive,
                  title: "Destinations",
                },
                {
                  key: "id_2",
                  link: continentURL,
                  title: continentName,
                },
                {
                  key: "id_3",
                  link: countryURL,
                  title: countryName,
                },
              ]}
              current={postTitle}
            />
          </article>
        </ScrollSection>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const travelStoryQuery = graphql`
  query PostBySlug($id: String!, $slug: String!) {
    allContentfulPost(filter: { country: { id: { eq: $id } } }) {
      edges {
        node {
          id
          title
          subTitle
          slug
          publishDate
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          country {
            slug
            title
            continent {
              title
              slug
            }
          }
          excerpt {
            excerpt
            childMarkdownRemark {
              html
            }
          }
          author {
            displayTitle
            firstname
            surname
            location
            slug
            picture {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 424
                height: 212
              )
            }
          }
        }
      }
    }
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    contentfulPost(slug: { eq: $slug }) {
      title
      subTitle
      seoTitle
      seoDescription
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 1920
            )
            description
          }
          ... on ContentfulComponent {
            contentful_id
            __typename
            displayTitle
            displayType
            type
            link
            location {
              lat
              lon
            }
            list {
              title
              text
              link
            }
            post {
              title
              slug
              minuteRead
              publishDate
              heroImage {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  width: 424
                  height: 212
                )
              }
              excerpt {
                excerpt
                childMarkdownRemark {
                  html
                }
              }
              country {
                continent {
                  slug
                  title
                  displayTitle
                }
                title
                slug
                displayTitle
              }
              author {
                surname
                firstname
                displayTitle
                slug
                picture {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    width: 424
                    height: 212
                  )
                }
              }
            }
            images {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                width: 1920
              )
              title
            }
            title
            youtubeId
            subTitle
            link
            autoplay
            excerpt {
              excerpt
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      excerpt {
        excerpt
        childMarkdownRemark {
          html
        }
      }
      minuteRead
      categories {
        slug
        title
      }
      country {
        slug
        title
        category {
          slug
          title
        }
        continent {
          title
          slug
        }
      }
      author {
        displayTitle
        firstname
        surname
        slug
        shortBio
        twitter
        location
        picture {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
      }
      publishDate
      heroVideo
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
      }
    }
  }
`;
