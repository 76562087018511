import React from "react";
import cx from "classnames";
import { BASE_URL } from "../../config";

export default function ({ title, text, slug, type }) {
  const location = `${BASE_URL}${slug}`;

  function windowOpen(e, href) {
    e.preventDefault();

    window.open(
      href,
      "Popup",
      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
    );
  }

  const icons = [
    {
      id: 1,
      className: "bg-social-facebook",
      href: `https://www.facebook.com/sharer/sharer.php?u=${location}`,
      type: "facebook",
      windowOpen: true,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="17" aria-label="Facebook"><path d="m8 2.822-1.45.001c-1.137 0-1.358.596-1.358 1.47v1.93h2.713l-.354 3.023H5.192V17H2.365V9.246H0V6.223h2.365V3.995C2.365 1.408 3.796 0 5.887 0 6.888 0 7.749.082 8 .119v2.703z" fill="currentColor" fill-rule="evenodd"/></svg>',
    },
    {
      id: 2,
      className: "bg-social-twitter",
      href: `https://twitter.com/share?text=${encodeURI(
        text || title
      )}&url=${location}`,
      type: "twitter",
      windowOpen: true,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="17" aria-label="Twitter"><path d="M19.762 4.79c0 5.67-4.54 12.21-12.843 12.21-2.55 0-4.921-.71-6.919-1.927.353.04.712.059 1.077.059 2.114 0 4.06-.685 5.605-1.837-1.975-.034-3.642-1.275-4.217-2.98a4.765 4.765 0 0 0 2.04-.074C2.439 9.847.885 8.112.885 6.034v-.055a4.668 4.668 0 0 0 2.044.537C1.717 5.746.919 4.432.919 2.944c0-.787.224-1.524.613-2.158 2.226 2.596 5.552 4.305 9.303 4.484a4.104 4.104 0 0 1-.117-.978c0-2.37 2.021-4.292 4.513-4.292 1.3 0 2.472.522 3.294 1.356a9.353 9.353 0 0 0 2.867-1.04c-.337 1-1.053 1.841-1.984 2.373A9.38 9.38 0 0 0 22 2.013a8.958 8.958 0 0 1-2.252 2.222c.008.184.014.368.014.555z" fill="currentColor" fill-rule="evenodd"/></svg>',
    },
    {
      id: 3,
      className: "bg-social-linkedin",
      type: "linkedin",
      href: `https://www.linkedin.com/shareArticle?mini=true&url=${location}&title=${encodeURI(
        title
      )}`,
      windowOpen: true,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" aria-label="Linked In"><path d="M14 14h-2.9V9.452c0-1.084-.02-2.479-1.508-2.479-1.51 0-1.74 1.182-1.74 2.402V14H4.954V4.649h2.781v1.278h.04c.387-.735 1.334-1.51 2.745-1.51 2.938 0 3.48 1.936 3.48 4.454V14zM1.684 3.371C.752 3.371 0 2.616 0 1.686S.752 0 1.684 0c.927 0 1.68.756 1.68 1.686 0 .93-.753 1.685-1.68 1.685zM3.134 14H.23V4.649h2.904V14z" fill="currentColor" fill-rule="nonzero"/></svg>',
    },
    {
      id: 4,
      className: "bg-social-whatsapp",
      type: "whatsapp",
      href: `https://api.whatsapp.com/send?text=${encodeURI(
        text || title
      )} ${location}`,
      windowOpen: true,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 30 30" aria-label="WhatsApp"><path fill="currentColor" d="M1.2 15.9c0 2.6.7 5.2 2 7.4L1.1 31 9 28.9c2.2 1.2 4.6 1.8 7.1 1.8C24.3 30.7 31 24 31 15.8c0-4-1.5-7.7-4.4-10.5C23.8 2.5 20 1 16.1 1 7.9 1 1.2 7.7 1.2 15.9zm4.7 7-.3-.5c-1.2-2-1.9-4.2-1.9-6.6C3.7 9 9.2 3.4 16.1 3.4c3.3 0 6.4 1.3 8.7 3.6s3.6 5.4 3.6 8.7c0 6.8-5.5 12.4-12.4 12.4-2.2 0-4.4-.6-6.3-1.7l-.5-.3-4.7 1.2 1.4-4.4zm10.2 7.8z"/><path fill="currentColor" d="M12.3 9.6c-.3-.6-.6-.6-.8-.6h-.7c-.2 0-.7.1-1 .5s-1.3 1.3-1.3 3.1 1.3 3.6 1.5 3.8 2.6 4.1 6.3 5.6c3.1 1.2 3.8 1 4.5.9s2.2-.9 2.5-1.8c.3-.9.3-1.6.2-1.8s-.3-.2-.7-.4c-.4-.2-2.2-1.1-2.5-1.2s-.6-.2-.8.2c-.2.4-1 1.2-1.2 1.5-.2.2-.4.3-.8.1s-1.6-.6-3-1.8c-1.1-1-1.9-2.2-2.1-2.6s0-.6.2-.8c.2-.2.4-.4.6-.7.2-.2.2-.4.4-.6.1-.2.1-.5 0-.7-.3-.1-1-1.9-1.3-2.7z"/></svg>',
    },
    {
      id: 5,
      className: "bg-social-email",
      type: "email",
      href: `mailto:?subject=${encodeURI(title)}&body=${location}`,
      windowOpen: false,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" aria-label="Email"><g fill="currentColor" fill-rule="evenodd"><path d="m0 1.25 6.75 5.4 6.75-5.4v9.693H0z"/><path d="M0 0h13.5L6.75 5.4z"/></g></svg>',
    },
  ];

  return (
    <div className={cx("shareModule relative share my-10 z-10")}>
      <strong className={cx("font-bold uppercase text-inherit text-xs")}>
        Share this story
      </strong>
      <ul className={cx("flex m-0 p-0")}>
        {icons.map((icon) => {
          if (
            type === "simple" &&
            (icon.type === "email" || icon.type === "whatsapp")
          ) {
            return "";
          }

          return (
            <li className={cx("mt-3.5 mr-2.5")} key={icon.id}>
              <a
                href={icon.href}
                target="_blank"
                rel="noreferrer"
                onClick={
                  icon.windowOpen ? (e) => windowOpen(e, icon.href) : () => null
                }
                aria-label={`Share on ${icon.type}`}
              >
                <span
                  className={cx(
                    "flex text-white items-center rounded-full justify-center",
                    icon.className
                  )}
                >
                  <i dangerouslySetInnerHTML={{ __html: icon.svg }} />
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
