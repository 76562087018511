import React, { useLayoutEffect, useRef } from "react";
import cx from "classnames";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

import { URLS } from "../../config/urls";
import TransitionLink from "../transition-link/transition-link";
import { Article } from "../../utils/types";
import Image from "../image/image";

interface Props {
  post?: Article;
  type?: string;
}

export default function ({ post, type }: Props) {
  const {
    author,
    country,
    heroImage,
    minuteRead,
    publishDate,
    slug,
    subTitle,
    title,
    heroVideo,
  } = post;
  const { firstname, picture, surname } = author;
  const linkToAuthor = `${URLS.Contributor}${author.slug}/`;
  const authorsName = `${firstname} ${surname}`;
  const publishedDate = new Date(publishDate);
  const displayDate = `${publishedDate.getFullYear()}`;

  const buildCoverImage = () => (
    <div
      className={cx(
        "asset coverImage absolute right-0 top-0 left-0 m-auto w-full object-cover"
      )}
    >
      {heroVideo ? (
        <div className="bg-black video-background relative overflow-hidden z-10">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube-nocookie.com/embed/${heroVideo}?autoplay=1&mute=1&playlist=${heroVideo}&loop=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        ""
      )}
      {heroImage ? <Image alt={title} gatsbyImageData={heroImage} /> : ""}
    </div>
  );

  if (type === "related") {
    return (
      <section
        className={cx(
          "coverRelated md:aspect-video flex items-center text-white justify-center relative overflow-hidden w-full cover--related"
        )}
      >
        <TransitionLink
          className={cx("block w-full")}
          data-cursor-type="keep-reading"
          to={`${URLS.Archive}${country.continent.slug}/${country.slug}/${slug}/`}
        >
          <div className={cx("p-5.5 relative z-10 w-full")}>
            <div className={cx("mb-4 uppercase font-semibold cover__title")}>
              {title}
            </div>
            <p className={cx("font-display italic cover__subtitle")}>
              {subTitle}
            </p>
          </div>
          {buildCoverImage()}
        </TransitionLink>
      </section>
    );
  }

  const component = useRef();

  useLayoutEffect(() => {
    gsap.config();
    gsap.registerPlugin(ScrollTrigger, SplitText);

    const content = component.current;

    const ctx = gsap.context(() => {
      const asset = content.querySelector(".asset");

      gsap.to(asset, {
        y: () => asset.offsetHeight - content.offsetHeight,
        ease: "none",
        scrollTrigger: {
          trigger: content,
          scrub: true,
          pin: false,
          markers: false,
          invalidateOnRefresh: true,
        },
      });
    }, component);

    return () => ctx.revert();
  }, []);

  return (
    <>
      <section
        ref={component}
        className="hero-fragment bg-black h-screen overflow-hidden relative w-full"
      >
        {buildCoverImage()}
        <header className="absolute bottom-0 left-0 p-5 lg:p-10 lg:pb-16 text-white z-10">
          <h1
            className={cx(
              "font-normal leading-relaxed mb-8 text-sm xl:text-lg tracking-wide uppercase"
            )}
          >
            {subTitle}
          </h1>
          <h2
            className={cx(
              "font-normal font-semibold leading-none mb-16 lg:mb-20 text-4xl xl:text-7xl"
            )}
          >
            <span>{title}</span>
          </h2>
          {picture && (
            <div className="flex items-center ">
              <TransitionLink
                to={linkToAuthor}
                className="block border-2 border-white  duration-300  h-16 overflow-hidden relative  rounded-full hover:-translate-y-1 transition w-16"
              >
                <div className="absolute duration-300 h-full rounded-full transition w-full z-10 hover:bg-white/30" />
                <Image
                  alt={authorsName}
                  className="block h-full w-full"
                  gatsbyImageData={picture}
                  borderRadius="100%"
                />
              </TransitionLink>
              <div className="font-semibold text-white text-sm whitespace-nowrap  ml-3">
                {authorsName} | {displayDate}
              </div>
            </div>
          )}
        </header>
      </section>

      {/* <section
      className={cx(
        "coverHero flex flex-col px-5.5 py-40 md:p-0 md:flex-row h-screen items-center text-white relative overflow-hidden w-full cover--hero"
      )}
    >
      <div className={cx("p-0 md:p-5.5 relative w-full z-10")}>
        <h1
          className={cx(
            "font-normal m-0 mb-5 leading-none uppercase cover__title"
          )}
        >
          <span>{title}</span>
        </h1>
        
      </div>
      <div
        itemScope
        itemType="https://schema.org/Person"
        className={cx(
          "relative md:absolute flex font-normal leading-none md:p-5.5 items-end md:bottom-16 left-0 right-0 w-full z-10 cover__meta"
        )}
      >
        <ul className={cx("flex flex-col sm:flex-row m-0 p-0 w-full")}>
          {picture ? (
            <li className={cx("flex items-center mb-5 sm:mb-0")}>
              <TransitionLink
                to={linkToAuthor}
                data-cursor-type="orange"
                className="block w-20 h-20 mr-4 transition-transform duration-300 hover:-translate-y-4"
              >
                <Image
                  alt={authorsName}
                  className="h-full w-full"
                  gatsbyImageData={picture}
                  borderRadius="100%"
                />
              </TransitionLink>
            </li>
          ) : (
            ""
          )}
          <li className={cx("flex items-center mb-5 sm:mb-0")}>
            <TransitionLink
              className={cx("has-hover-underline hover:text-primary-orange")}
              to={linkToAuthor}
              itemProp="name"
              data-cursor-type="orange"
            >
              by {authorsName}
            </TransitionLink>{" "}
            <div className={cx("mx-2.5")}>&mdash;</div>{" "}
            <TransitionLink
              className={cx("uppercase hover:text-primary-orange")}
              to={`${URLS.Archive}${country.continent.slug}/`}
              data-cursor-type="orange"
            >
              <span>{country.continent.title}</span>
            </TransitionLink>
            ,&nbsp;
            <TransitionLink
              className={cx("uppercase  hover:text-primary-orange")}
              to={`${URLS.Archive}${country.continent.slug}/${country.slug}/`}
              data-cursor-type="orange"
            >
              <span>{country.title}</span>
            </TransitionLink>
          </li>
          <li className={cx("flex items-center mb-5 sm:mb-0 sm:ml-auto")}>
            {displayDate}
            {minuteRead ? <span>&nbsp;&bull; {minuteRead} min read</span> : ""}
          </li>
        </ul>
      </div>
      {buildCoverImage()}
    </section> */}
    </>
  );
}
