import React, { useLayoutEffect, useRef } from "react";
import cx from "classnames";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import ScrollTrigger from "gsap/ScrollTrigger";

import { URLS } from "../../config/urls";
import TransitionLink from "../transition-link/transition-link";
import { Country, Excerpt } from "../../utils/types";
import Share from "../share/share";

interface Props {
  country?: Country;
  excerpt?: Excerpt;
  share?: {
    title?: string;
    text?: string;
    slug?: string;
  };
}

export default function ({ country, excerpt, share }: Props) {
  const component = useRef();

  useLayoutEffect(() => {
    gsap.config();
    gsap.registerPlugin(ScrollTrigger, SplitText);

    if (component && component.current) {
      const content = component.current.querySelector("p");
      let split = new SplitText(content, { type: "lines" });

      const ctx = gsap.context(() => {
        function splitText() {
          split.revert();
          split = new SplitText(content, { type: "lines" });
          split.lines.forEach((target) => {
            gsap.to(target, {
              backgroundPositionX: 0,
              ease: "none",
              scrollTrigger: {
                trigger: target,
                markers: false,
                scrub: 0.5,
                start: "top center",
                end: "bottom center",
              },
            });
          });
        }

        const someDelay = gsap.delayedCall(0.2, resetScrollText).pause();
        window.addEventListener("resize", () => someDelay.restart(true));

        function resetScrollText() {
          split.revert();
          splitText();
        }

        splitText();

        return () => split && split.revert();
      }, component);
    }

    return () => ctx.revert();
  }, []);

  return (
    <section
      className={cx(
        "-mt-4 mb-10 excerpt relative mx-auto block relative text-white w-full z-10"
      )}
    >
      <img
        data-speed="0.9"
        src={`/svg/${country.continent.slug}.svg`}
        alt={country.continent.slug}
        className={`absolute hidden lg:block left-5 opacity-10 top-0 w-2/12 z-0 excerpt__image--${country.continent.slug}`}
        loading="lazy"
      />
      <div className="bg-black lg:flex m-0 p-5 lg:px-10 lg:pt-8 lg:pb-12 w-full">
        <div
          data-speed="1.05"
          className="font-bold inline-block leading-none mb-5 relative text-base uppercase lg:mt-1 lg:w-3/12"
        >
          <TransitionLink
            to={`${URLS.Archive}${country.continent.slug}/${country.slug}/`}
            className="has-underline text-primary-orange hover:text-orange-light mr-1"
            data-cursor-type="orange"
          >
            {country.title}
          </TransitionLink>{" "}
          &bull;
          <TransitionLink
            to={`${URLS.Archive}${country.continent.slug}/`}
            className="has-underline text-primary-orange hover:text-orange-light ml-2"
            data-cursor-type="orange"
          >
            {country.continent.title}
          </TransitionLink>
        </div>
        <div
          data-speed="0.95"
          className={cx(
            "lg:w-7/12 !leading-10 text-xl lg:text-2xl excerpt__content"
          )}
        >
          <div
            ref={component}
            className={cx("font-display font-normal italic mb-14")}
            dangerouslySetInnerHTML={{
              __html: excerpt.childMarkdownRemark.html,
            }}
          />
          <Share title={share.title} text={share.text} slug={share.slug} />
        </div>
      </div>
    </section>
  );
}
